import React,{useEffect,useContext,useState} from 'react'
import { Button, Modal } from 'react-bootstrap'
import './AllDetailModal.css'
import { Context } from '../../../utils/context';

function AllDetailModal(props) {

  
  const { getData, postData, imageUrl } = useContext(Context);

  
  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();

  return (
    <>
     <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='AllDetailModal_modal'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         {props.sellercardsDetails?.childsubcategory?.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
    <div className='imggdvivv'>

       
      <img src={imageUrl  + props.sellercardsDetails?.image} alt={props.sellercardsDetails?.title} className="imfluid mb-3" />
      </div>
      <p className='text'>{htmlToReactParser?.parse(props.sellercardsDetails?.descripation)}</p>
      </Modal.Body>
     
    </Modal>
    </>
  )
}

export default AllDetailModal


