import React, { useState } from 'react'
import './DrDetails.css'
import { Col, Container, Row } from 'react-bootstrap'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
function DrDetails() {

    const [swiperInstance, setSwiperInstance] = useState(null);

    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };

    const data = [
        {
            name: "Jacqueline Nathe",
            place: "Nashik",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        },
        {
            name: "Rakhi Deshmukh",
            place: "Mumbai",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        },
    ];
    return (
        <>
            <section className='drdetails_section'>
                <Container>
                    <div className='dedetails_main'>
                        <div className='drimagediv'>
                            <img className="drimage" src={process.env.PUBLIC_URL + "/assets/images/About/gp2.png"} alt="Logo" />
                        </div>
                        <div>
                            <Row>
                                <Col xxl={7} xl={7} lg={7} md={9} sm={11} className='mx-auto'>
                                    <div className='text-center'>
                                        <Swiper
                                            className="mySwiper"
                                            // modules={[Pagination]}
                                            // pagination={{ clickable: true }}
                                            onSwiper={(swiper) => setSwiperInstance(swiper)}
                                        >
                                            {data.map((item, index) => (
                                                <SwiperSlide key={index}>
                                                    <div>
                                                        <p className="testimonial-text testtext">{item.name}</p>
                                                        <p className="text-testimonial">{item.place}</p>
                                                        <div className="bordermain mx-auto mb-4"></div>
                                                        <p className="p-text">{item.text}</p>
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>

                                        <div className="slider_bt ">
                                            <div className="backbtn me-3" onClick={() => handleMainSliderClick("prev")}>

                                                <FontAwesomeIcon icon={faArrowLeft} className="iconmain" />
                                            </div>
                                            <div className="nextbtn" onClick={() => handleMainSliderClick("next")}>
                                                <FontAwesomeIcon icon={faArrowRight} className="iconmain" />

                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>

            </section>
        </>
    )
}

export default DrDetails