import React from 'react'
import './Counter.css'
import { Col, Container, Row } from 'react-bootstrap'
import CountUp from 'react-countup';
function Counter() {
    return (
        <section className='counter_section'>
            <Container>
                <div className='counter_div'>
                    <Row >
                        <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6} className='mx-auto'>
                            <div className='conter'>
                                <div className='counts' >
                                    <CountUp end={30} duration={1.5}/>+
                                   
                                </div>
                                <div className='counttext'>
                                    <p className='offertext'>Service Offered</p>
                                </div>
                               
                            </div>

                        </Col>
                        <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6} className='mx-auto'>
                            <div className='conter'>
                                <div className='counts'>
                                    <CountUp end={100} duration={1.5}/>+
                                </div>
                                <div className='counttext'>
                                    <p className='offertext'>Happy Clients</p>
                                </div>
                            </div>

                        </Col>
                        <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6} className='mx-auto'>
                            <div className='conter'>
                                <div className='counts'>
                                    <CountUp end={25}  duration={1.5}/>+
                                </div>
                                <div className='counttext'>
                                    <p className='offertext'>Years in Service</p>
                                </div>
                            </div>

                        </Col>
                        <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6} className='mx-auto'>
                            <div className='conter'>
                                <div className='counts'>
                                    <CountUp end={4.5} duration={1.5}  decimals={1}/>
                                </div>
                                <div className='counttext'>
                                    <p className='offertext mb-0'>Ratings on Google</p>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default Counter