import React, { useContext } from "react";
import "./TreatmentBanner.css";
import { Context } from "../../../utils/context";
function TreatmentBanner({ treatmentData }) {
  const { getData, postData, imageUrl } = useContext(Context);
  return (
    <>
      <section className=" treatbanner treatbannersss">
        <div className="Banner_holder">
          <div>
            <div className="img-holder">
              <img
                src={imageUrl + treatmentData?.subcategory?.banner_image}
                className="treatbanner-img"
              />
            </div>
            <div className="banner-heading-text-holder">
              <div className="container">
                <div className="text-holder ">
                  <h1>{treatmentData?.subcategory?.name}</h1>
                  {/* <p className="textrestore">
                    "Restore, Protect, Smile: Your Guide to Dental Fillings!"
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TreatmentBanner;
