import React, { useState, useContext, useEffect } from "react";
import "./Banner.css";
import { Context } from "../../../utils/context";



function Banner() {



    const { getData, postData, imageUrl } = useContext(Context);
    const [bannerData, setBannerData] = useState();
    const [bannerType, setBannerType] = useState("home");

    const getBanner = async () => {
        const res = await getData(`/banner?type=${bannerType}`);
        if (res?.success) {
            setBannerData(res.data.list[0]);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getBanner();
    }, []);

    return (
        <section className="home-banner">
            <div className="container-fluid p-0">
                <div className="image-holder">
                    <img
                        className="home-banner"
                        src={imageUrl + bannerData?.image}
                        // src={process.env.PUBLIC_URL + "/assets/Banner/banner.png"}
                        alt="Logo"
                    />
                </div>
            </div>
        </section>
    );
}

export default Banner;
