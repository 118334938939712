import React, { useEffect, useState, useContext } from "react";
import { Col, Container, Row } from 'react-bootstrap'
import ServiceCard from '../CommonComponents/ServiceCard/ServiceCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import AllDetailModal from '../CommonComponents/AllDetailModal/AllDetailModal';
import Breadcrumbs from "../CommonComponents/Breadcrumbs/Breadcrumbs";
import { useParams } from 'react-router-dom';
import { Context } from "../../utils/context";

function TreatmentCategory() {
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        { text: "Home", link: "/" },
        { text: "Treatment Category", link: "#" },
        ,
    ]);

    const [modalShow, setModalShow] = React.useState(false);

    const [storedata, setStoredata] = React.useState("");

    const icons = <FontAwesomeIcon icon={faArrowRight} />;
    const sellercardsDetails = [
        {
            id: 1,
            title: "Invisalign",
            content: "Unlock your brightest smile with our comprehensive dental services. From routine check-ups to advanced treatments, we prioritize your oral health & confidence.",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/srvice1.png",
            icons: <FontAwesomeIcon icon={faArrowRight} />
        },
        {
            id: 2,
            title: "Aligners",
            content: "Unlock your brightest smile with our comprehensive dental services. From routine check-ups to advanced treatments, we prioritize your oral health & confidence.",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service2.png",

            icons: <FontAwesomeIcon icon={faArrowRight} />
        },
        {
            title: "Braces",
            content: "Unlock your brightest smile with our comprehensive dental services. From routine check-ups to advanced treatments, we prioritize your oral health & confidence.",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            icons: <FontAwesomeIcon icon={faArrowRight} />
        },
        {
            title: "Eyebrow Microblading",
            content: "Unlock your brightest smile with our comprehensive dental services. From routine check-ups to advanced treatments, we prioritize your oral health & confidence.",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            icons: <FontAwesomeIcon icon={faArrowRight} />
        },
        {
            title: "BB Glow",
            content: "Unlock your brightest smile with our comprehensive dental services. From routine check-ups to advanced treatments, we prioritize your oral health & confidence.",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
        },
        {
            title: "Teeth Whitening",
            content: "Unlock your brightest smile with our comprehensive dental services. From routine check-ups to advanced treatments, we prioritize your oral health & confidence.",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            icons: <FontAwesomeIcon icon={faArrowRight} />
        },
        {
            title: "Veneers & Crown",
            content: "Unlock your brightest smile with our comprehensive dental services. From routine check-ups to advanced treatments, we prioritize your oral health & confidence.",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            icons: <FontAwesomeIcon icon={faArrowRight} />
        },
        {
            title: "Composite Bonding",
            content: "Unlock your brightest smile with our comprehensive dental services. From routine check-ups to advanced treatments, we prioritize your oral health & confidence.",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            icons: <FontAwesomeIcon icon={faArrowRight} />
        },
        {
            title: "Implants",
            content: "Unlock your brightest smile with our comprehensive dental services. From routine check-ups to advanced treatments, we prioritize your oral health & confidence.",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            icons: <FontAwesomeIcon icon={faArrowRight} />
        },
        {
            title: "Lips Fillers",
            content: "Unlock your brightest smile with our comprehensive dental services. From routine check-ups to advanced treatments, we prioritize your oral health & confidence.",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            icons: <FontAwesomeIcon icon={faArrowRight} />
        },
        {
            title: "Dental Jwellery",
            content: "Unlock your brightest smile with our comprehensive dental services. From routine check-ups to advanced treatments, we prioritize your oral health & confidence.",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            icons: <FontAwesomeIcon icon={faArrowRight} />
        },
    ];

    const { id } = useParams();

    const { getData, postData, imageUrl } = useContext(Context);
    const [treatmentData, setTreatmentData] = useState([]);
    const getTreatmentTypes = async () => {
        try {
          const res = await getData(`/childtreatmentdetail?sub_category_id=${id}`);
          if (res?.success) {
            setTreatmentData(res.data);
            // window.location.reload();
          } else {
            console.error('Error fetching data:', res?.error);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      useEffect(() => {
        getTreatmentTypes();
      }, [id]);


    return (
        <>
            <section className='tratmentcategory_section py-4'>
                <Container>
                    <div>
                        <Breadcrumbs items={breadcrumbItems} />
                    </div>

                    <div className='treatmentcat_div'>
                        <Row>
                            {treatmentData?.map((item, index) => (
                                <>
                                    <Col xxl={4} xl={4} lg={4} md={6} sm={6} xs={12} className='mb-4'>
                                        <div className='treatcarddiv'>
                                            <ServiceCard data={item} icons={icons} onclick={() => { setStoredata(item); setModalShow(true); }} />
                                        </div>


                                    </Col>


                                </>
                            ))}
                        </Row>
                    </div>
                </Container>

                <AllDetailModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    sellercardsDetails={storedata}
                />

            </section>
        </>
    )
}

export default TreatmentCategory