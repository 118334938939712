import React, { useEffect, useState, useContext } from "react";
import "./Treatments.css";
import { useParams } from "react-router-dom";
import TreatmentBanner from "./TreatmentBanner/TreatmentBanner";
import DentalFalling from "./DentalFalling/DentalFalling";
import Counter from "../CommonComponents/Counter/Counter";
import Whitening_work from "./Whitening_work/Whitening_work";
import TeethStain from "./TeethStain/TeethStain";
import WhyChoose from "./WhyChoose/WhyChoose";
import Offer from "./Offer/Offer";
import MakeAppointment from "./MakeAppointment/MakeAppointment";
import Tips from "./Tips/Tips";
import { Context } from "../../utils/context";
function Treatments() {
  const { id } = useParams();

  const { getData, postData, imageUrl } = useContext(Context);
  const [treatmentData, setTreatmentData] = useState([]);
  const getTreatment = async () => {
    try {
      const res = await getData(`/treatmentdetail?sub_category_id=${id}`);
      if (res?.success) {
        // console.log(res.data, "dssdads");

        setTreatmentData(res.data);
        // window.location.reload();
      } else {
        console.error("Error fetching data:", res?.error);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getTreatment();
  }, [id]);

  return (
    <>
      <TreatmentBanner treatmentData={treatmentData} />
      <DentalFalling treatmentData={treatmentData} />
      <Counter />
      <Whitening_work treatmentData={treatmentData} />
      <TeethStain treatmentData={treatmentData} />
      <Tips treatmentData={treatmentData} />
      <WhyChoose treatmentData={treatmentData} />
      <Offer />
      <MakeAppointment />
    </>
  );
}

export default Treatments;
