import React, { useContext, useEffect, useState } from 'react'
import './BlogMain.css'
import { Col, Container, Pagination, Row } from 'react-bootstrap'
import ServiceCard from '../../CommonComponents/ServiceCard/ServiceCard';
import RecentBlog from '../RecentBlog/RecentBlog';
import { Link } from 'react-router-dom';
import ServiceCard2 from '../../CommonComponents/ServiceCard/ServiceCard2';
import { Context } from '../../../utils/context';
function BlogMain() {

    const icons = "READ MORE"

    const sellercardsDetails = [
        {
            id: 1,
            title: "Skin Solutions Made Simple",
            content: "Discover radiant skin with our tailored treatment services. From rejuvenating facials to targeted therap- -ies, we're dedicated to enhancing your natural beauty.",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/srvice1.png",
            detailsLink: "/bloginner",
            icons: "READ MORE",
        },
        {
            id: 2,
            title: "Now hair treatment is no more a headache",
            content: "Revitalize your mane with our expert hair treatments. From luxurious conditioning to trendy styles, we're here to make your hair dreams a reality. Step into...",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service2.png",
            detailsLink: "/bloginner",
            icons: "READ MORE",
        },
        {
            title: "Now hair treatment is no more a headache",
            content: "Revitalize your mane with our expert hair treatments. From luxurious conditioning to trendy styles, we're here to make your hair dreams a reality. Step into...",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            detailsLink: "/bloginner",
            icons: "READ MORE",
        },
        {
            title: "Skin Solutions Made Simple",
            content: "Discover radiant skin with our tailored treatment services. From rejuvenating facials to targeted therap- -ies, we're dedicated to enhancing your natural beauty.",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            detailsLink: "/bloginner",

        },
        {
            title: "Skin Solutions Made Simple",
            content: "Discover radiant skin with our tailored treatment services. From rejuvenating facials to targeted therap- -ies, we're dedicated to enhancing your natural beauty.",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            detailsLink: "/bloginner",
            icons: "READ MORE",
        },
        {
            title: "Now hair treatment is no more a headache",
            content: "Revitalize your mane with our expert hair treatments. From luxurious conditioning to trendy styles, we're here to make your hair dreams a reality. Step into...",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            detailsLink: "/bloginner",
            icons: "READ MORE",
        },
    ];

    const recent = [
        {
            id: 1,
            content: "Now hair treatment is no more a headache",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/srvice1.png",
            title: "Dental Services",
            detailsLink: "/bloginner",
        },
        {
            id: 2,
            content: "Now hair treatment is no more a headache",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service2.png",
            title: "Dental Services",
            detailsLink: "/bloginner",
        },
        {
            content: "Now hair treatment is no more a headache",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            detailsLink: "/bloginner",
        },
        {
            content: "Now hair treatment is no more a headache",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            title: "Dental Services",
        },
        {
            content: "Now hair treatment is no more a headache",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            title: "Dental Services",
            detailsLink: "/bloginner",
        },
        {
            content: "Now hair treatment is no more a headache",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            title: "Dental Services",
            detailsLink: "/bloginner",
        },
        {
            content: "Now hair treatment is no more a headache",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            title: "Dental Services",
            detailsLink: "/bloginner",
        },
        {
            content: "Now hair treatment is no more a headache",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            title: "Dental Services",
            detailsLink: "/bloginner",
        },
    ];

    const category = [
        {
            name: "Dental (8)",
        },
        {
            name: "Skin Care (3)",
        },
        {
            name: "Hair Care Treatment (2)",
        },
    ];

    const tags = [
        {
            name: "Cosmetics",
        },
        {
            name: "Health",
        },
        {
            name: "Lifestyle",
        },
    ];



    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();

    const { getData, postData, imageUrl } = useContext(Context);
    const [blogData, setBlogData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [tagsData, setTagsData] = useState([]);
    const [pageLinks, setPageLinks] = useState([]);
    const [currentPage, setCurrentPage] = useState("");
    const [data, setData] = useState();
    const [page, setPage] = useState(1);

    const [firstBlogData, setFirstBlogData] = useState();

    const getBlog = async () => {
        const res = await getData(`/blog?${page}`);
        if (res?.success) {
            setBlogData(res?.data?.list?.data);
            setPageLinks(res?.data?.list?.links);
            setCurrentPage(res?.data?.list?.current_page);
            setData(res?.data?.list);
            setCategoryData(res?.data?.category);
            setTagsData(res?.data?.tags);
            setFirstBlogData(res?.data?.list?.data[0]);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getBlog();
    }, [page]);

    const formattedDate = new Date(firstBlogData?.created_at).toLocaleDateString('en-GB');
    return (
        <>
            <section className='blogmain_section py-5'>
                <Container>
                    <div className='blogmain_div'>
                        <Row>
                            <Col xxl={8} xl={8} lg={8} md={12}>
                                <div className='blogmaincont1'>
                                    <div className='topblog'>
                                        <div className='blogmainimgdiv mb-3'>
                                            <img className="blogimage" src={imageUrl + firstBlogData?.image} alt="Logo" />
                                        </div>
                                        <h6 className='subtitle'>{firstBlogData?.title}</h6>
                                        <p className='text'>{htmlToReactParser.parse(firstBlogData?.description)}</p>
                                        <div className='d-flex justify-content-between'>
                                            <Link to={`/bloginner/${firstBlogData?.id}`}><p className='readmore'>READ MORE</p></Link>
                                            <p className='readmore'>{formattedDate}</p>
                                        </div>

                                    </div>
                                    <div className='mt-4'>
                                        <Row>
                                            {blogData?.map((item, index) => (
                                                <Col xxl={6} xl={6} lg={6} md={6} sm={6} className='mb-4'>
                                                    <div>
                                                        <ServiceCard2 data={item} icons={icons} />
                                                    </div>

                                                </Col>
                                            ))}
                                        </Row>
                                    </div>

                                    <div>
                                        <Pagination>
                                            {pageLinks?.map((val) => (
                                                <>
                                                    {currentPage != 1 && (
                                                        <>
                                                            {val?.label === "&laquo; Previous" && (
                                                                <Pagination.Prev onClick={() => {
                                                                    setPage(val?.url?.split("?")[1]);
                                                                }}
                                                                >
                                                                    {htmlToReactParser.parse(val?.label)}
                                                                </Pagination.Prev>
                                                            )}
                                                        </>
                                                    )}
                                                    {val?.label !== "&laquo; Previous" && val?.label !== "Next &raquo;" && (
                                                        <Pagination.Item
                                                            onClick={() => {
                                                                setPage(val?.url?.split("?")[1]);
                                                            }}
                                                        >
                                                            {htmlToReactParser.parse(val?.label)}
                                                        </Pagination.Item>
                                                    )}

                                                    {data?.last_page != currentPage && (
                                                        <>
                                                            {val?.label === "Next &raquo;" && (
                                                                <Pagination.Next
                                                                    onClick={() => {
                                                                        setPage(val?.url?.split("?")[1]);
                                                                    }}
                                                                >{htmlToReactParser.parse(val?.label)}
                                                                </Pagination.Next>

                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            ))}
                                        </Pagination>

                                        {/* <Pagination>
                                            {pageLinks?.map((val) => (
                                                <>
                                                    {val?.label === "&laquo; Previous" && (
                                                        <button
                                                            className="ms-3 previous"
                                                            onClick={() => {
                                                                setPage(val?.url?.split("?")[1]);
                                                            }}
                                                        >
                                                            {htmlToReactParser.parse(val?.label)}
                                                        </button>
                                                    )}

                                                    {val?.label !== "&laquo; Previous" && val?.label !== "Next &raquo;" && (
                                                        <button
                                                            className={val?.active ? "ms-3 active number" : "ms-3 number"}
                                                            onClick={() => {
                                                                setPage(val?.url?.split("?")[1]);
                                                            }}
                                                        >
                                                            {htmlToReactParser.parse(val?.label)}
                                                        </button>
                                                    )}

                                                    {val?.label === "Next &raquo;" && (
                                                        <button
                                                            className="ms-3 next"
                                                            onClick={() => {
                                                                setPage(val?.url?.split("?")[1]);
                                                            }}
                                                        >
                                                            {htmlToReactParser.parse(val?.label)}
                                                        </button>
                                                    )}
                                                </>
                                            ))}
                                        </Pagination> */}
                                    </div>

                                </div>
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={12}>
                                <div className='blogmaincont2 '>

                                    <div className='blogrecent mb-4'>
                                        {blogData?.map((recent, index) => (
                                            <div className='mb-3'>
                                                <RecentBlog data={recent} />
                                            </div>
                                        ))}
                                    </div>
                                    <div className='categorydiv mb-5'>
                                        <h6 className='puptagttitle mb-3'>Categories</h6>
                                        {categoryData?.map((data, index) => (
                                            <h6 className='tagss tag1 mb-3'>{data?.category?.name} - {data?.count}</h6>
                                        ))}

                                    </div>
                                    <div className='popular_div'>
                                        <h6 className='puptagttitle mb-3'>Popular Tags</h6>
                                        <div className='popular_tag'>
                                            {tagsData?.map((tags, index) => (
                                                <p className='tagss tags2 mb-3 me-3'>{tags.name}</p>
                                            ))}
                                        </div>

                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </Container>
            </section >
        </>
    )
}

export default BlogMain