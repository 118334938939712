import React from "react";
import "./Whitening_work.css";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
function Whitening_work({ treatmentData }) {
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  return (
    <>
      <section className="whitening_section">
        <Container>
          <div className="whitenig_div">
            <h3 className="title-main mb-4">
              How Does <br />
              {treatmentData?.category?.name}{" "}
              <span className="highlight">
                {" "}
                {treatmentData?.subcategory?.name}?
              </span>
            </h3>

            <p className="text">
              {htmlToReactParser?.parse(treatmentData?.howdoes)}
            </p>
            <Link to={"https://wa.me/+918421054943"} target="_blank">
              {" "}
              <p className="text text-decoration-underline pt-3">
                BOOK AN APPOINTMENT
              </p>
            </Link>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Whitening_work;
