import React from "react";
import "./InnerUniverse.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
function InnerUniverse() {
  return (
    <>
      <section className="universe_section pt-5">
        <Container>
          <div className="universe_div_main ">
            <Row>
              <Col xxl={6} xl={6} lg={6} md={12}>
                <div className="universeimage_div">
                  <img
                    className="universeimage"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/homepage/cutegirl.png"
                    }
                    alt="Logo"
                  />
                </div>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={12} className="my-auto">
                <div className=" universetext">
                  <p className="text-sub">WHAT WE DO</p>

                  <h3 className="title-main mb-4">
                    Know what’s <br />
                    happening{" "}
                    <span className="highlight">within inner universe</span>
                  </h3>
                  <p className="p-text">
                    Unlock the secrets of your inner universe — your teeth, skin
                    and hair. Discover what's happening beneath the surface and
                    embrace your radiant self. Dive deep into self-care and
                    unleash your natural beauty.{" "}
                  </p>
                  <div className="universec_div">
                    <Link to={"https://wa.me/+918421054943"} target="_blank">
                      {" "}
                      <p className="text">BOOK AN APPOINTMENT</p>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}

export default InnerUniverse;
