import React, { useEffect } from "react";
import Terms_And_Condition_Banner from "./Terms_And_Condition_Banner/Terms_And_Condition_Banner";
import Terms_And_Condition_Content from "./Terms_And_Condition_Content/Terms_And_Condition_Content";

function Terms_And_Condition() {
  // useEffect(() => {
  //   window.scrollTo(0, 0); // Scroll to the top when component mounts
  // }, []);
  return (
    <>
      <Terms_And_Condition_Banner />
      <Terms_And_Condition_Content />
    </>
  );
}

export default Terms_And_Condition;
