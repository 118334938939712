import React from 'react'
import OurBanner from './AboutBanner/AboutBanner'
import Specialist from '../home/Specialist/Specialist'
import OurDiscription from './OurDiscription/OurDiscription'
import BookApointment from '../home/BookApointment/BookApointment'
import ClientSays from '../home/ClientSays/ClientSays'
import DrDetails from '../About/DrDetails/DrDetails'
import OurDiscription1 from './OurDiscription/Ourdescription1'
import OurDiscription2 from './OurDiscription/Ourdescription2'
import OurDiscription3 from './OurDiscription/OurDiscription3'
import OurDiscription5 from './OurDiscription/OurDiscription5'
import OurDiscription4 from './OurDiscription/OurDiscription4'

function OurTeam() {
  return (
   <>
   <OurBanner/>
   <OurDiscription1/>
   <OurDiscription/>
   <OurDiscription2/>
   <OurDiscription3/>
   <OurDiscription4/>
   <OurDiscription5/>
   <BookApointment />
   <DrDetails/>
   </>
  )
}

export default OurTeam