import React from 'react'
import { Container } from 'react-bootstrap'
import './BlogBanner.css'
function BlogBanner() {
  return (
    <>
    <section className='blogbanner_section'>
        <Container>
            <div className='blogmain'>
                <h2 className='blogtitle heading mb-3'>Blog</h2>
                <p className='blogtext mb-0'>"Explore, Engage, Evolve: Your Journey Starts Here!"</p>
            </div>
        </Container>
    </section>
    </>
  )
}

export default BlogBanner