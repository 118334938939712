import React, { useEffect } from 'react'
import BlogBanner from './BlogBanner/BlogBanner'
import BlogMain from './BlogMain/BlogMain'

function Blog() {

  // useEffect(() => {
  //   window.scrollTo(0, 0); // Scroll to the top when component mounts
  // }, []);
  return (
    <>
    <BlogBanner/>
    <BlogMain/>
    </>
  )
}

export default Blog