import React from 'react'
import GallaryBanner from './GallaryBanner/GallaryBanner'
import GallarySwiper from './GallarySwiper/GallarySwiper'

function Galary() {
  return (
   <>
   <GallaryBanner/>
   <GallarySwiper/>
   </>
  )
}

export default Galary