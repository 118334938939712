import React, { useContext, useEffect, useState } from "react";
import "./Services.css";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import ServiceCard3 from "../../CommonComponents/ServiceCard/ServiceCard3";
import "./Services.css";
import { icon } from "@fortawesome/fontawesome-svg-core";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context } from "../../../utils/context";
function Services() {
  const icons = <FontAwesomeIcon icon={faArrowRight} />;
  // const sellercardsDetails = [
  //     {
  //       id: 1,
  //       title:"Dental Services",
  //       content: "Unlock your brightest smile with our comprehensive dental services. From routine check-ups to advanced treatments, we prioritize your oral health & confidence.",
  //       image:
  //         process.env.PUBLIC_URL + "/assets/images/homepage/srvice1.png",
  //       detailsLink: "/bloginner",
  //       icons:<FontAwesomeIcon icon={faArrowRight} />
  //     },
  //     {
  //       id: 2,
  //       title:"Skin Treatment",
  //       content: "Unlock your brightest smile with our comprehensive dental services. From routine check-ups to advanced treatments, we prioritize your oral health & confidence.",
  //       image:
  //         process.env.PUBLIC_URL + "/assets/images/homepage/service2.png",
  //       detailsLink: "/bloginner",
  //       icons:<FontAwesomeIcon icon={faArrowRight} />
  //     },
  //     {
  //       title:"Hair Treatment",
  //       content: "Unlock your brightest smile with our comprehensive dental services. From routine check-ups to advanced treatments, we prioritize your oral health & confidence.",
  //       image:
  //         process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
  //         detailsLink: "/bloginner",
  //         icons:<FontAwesomeIcon icon={faArrowRight} />
  //     },
  //     {
  //       title:"Dental Services",
  //       content: "Unlock your brightest smile with our comprehensive dental services. From routine check-ups to advanced treatments, we prioritize your oral health & confidence.",
  //       image:
  //         process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
  //         detailsLink: "/bloginner",
  //         icons:<FontAwesomeIcon icon={faArrowRight} />
  //     },
  //     {
  //       title:"Dental Services",
  //       content: "Unlock your brightest smile with our comprehensive dental services. From routine check-ups to advanced treatments, we prioritize your oral health & confidence.",
  //       image:
  //         process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
  //     },
  //     {
  //       title:"Dental Services",
  //       content: "Unlock your brightest smile with our comprehensive dental services. From routine check-ups to advanced treatments, we prioritize your oral health & confidence.",
  //       image:
  //         process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
  //         detailsLink: "/bloginner",
  //         icons:<FontAwesomeIcon icon={faArrowRight} />
  //     },
  //     {
  //       title:"Dental Services",
  //       content: "Unlock your brightest smile with our comprehensive dental services. From routine check-ups to advanced treatments, we prioritize your oral health & confidence.",
  //       image:
  //         process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
  //         detailsLink: "/bloginner",
  //         icons:<FontAwesomeIcon icon={faArrowRight} />
  //     },
  //     {
  //       title:"Dental Services",
  //       content: "Unlock your brightest smile with our comprehensive dental services. From routine check-ups to advanced treatments, we prioritize your oral health & confidence.",
  //       image:
  //         process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
  //         detailsLink: "/bloginner",
  //         icons:<FontAwesomeIcon icon={faArrowRight} />
  //     },
  //   ];

  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const { getData, postData, imageUrl } = useContext(Context);
  const [blogData, setBlogData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [tagsData, setTagsData] = useState([]);
  const [pageLinks, setPageLinks] = useState([]);
  const [currentPage, setCurrentPage] = useState("");
  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const getBlog = async () => {
    const res = await getData(`/blog`);
    if (res?.success) {
      setBlogData(res?.data?.list?.data);
      setPageLinks(res?.data?.list?.links);
      setCurrentPage(res?.data?.list?.current_page);
      setData(res?.data?.list);
      setCategoryData(res?.data?.category);
      setTagsData(res?.data?.tags);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getBlog();
  }, [page]);

  return (
    <>
      <section className="service_section ">
        <Container>
          <h3 className="title-main">
            Our
            <br />
            <span className="highlight ">Treatments Offered</span>
          </h3>
          <div className="service_main_div">
            <Swiper
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                486: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1400: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1900: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              pagination={{
                dynamicBullets: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {blogData?.map((item, index) => (
                <SwiperSlide key={index}>
                  <Link to={`/treatments/${item?.id}`}>
                    <ServiceCard3 data={item} icons={icons} />
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Services;
