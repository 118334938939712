import React, { useState } from 'react';
import './MakeAppointment.css';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

function MakeAppointment() {
    const [showMap, setShowMap] = useState(false);

    const toggleMap = () => {
        setShowMap(!showMap);
    };

    return (
        <section className='makemain'>
            <div className='makeapoint_section'>
                <Container>
                    <div className='makeapoint_main'>
                        <Row>
                            <Col xxl={6} xl={6} lg={6} md={12}>
                                <div className='time_div'>
                                    <h3 className="title-main mb-4">
                                        Make an  <br /><span className="highlight"> Appointment</span></h3>
                                    <p className='workhrtext'>Working Hours</p>
                                    <p className='text'>Mon - Sat 9:00 am to 8:00pm</p>
                                    <p className='text'>Mon - Sat 9:00 am to 8:00pm</p>
                                    <p className=' text text-decoration-underline' onClick={toggleMap} style={{ cursor: 'pointer' }}>Find Us on map</p>
                                </div>
                            </Col>
                            <Col xxl={6} xl={6} lg={6} md={12}>
                                <div className='time_div'>
                                    <div className='makeimg_div mb-3'>
                                        <img className="makeimg" src={process.env.PUBLIC_URL + "/assets/images/Treatments/doublequama.png"} alt="Logo" />
                                    </div>
                                    <p className='nametext mb-0'>Arjun Mane</p>
                                    <p className='text'>Nanded</p>
                                    <div className='brdrr'></div>
                                    <p className='text'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <div className={`mapdiv ${showMap ? 'show' : ''}`}>
               
                            <iframe
                                className="gmap_iframe"
                                frameBorder="0"
                                scrolling="no"
                                marginHeight="0"
                                marginWidth="0"
                                src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=pune&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                            ></iframe>
                      
                        <div className='angleeicn' onClick={toggleMap} style={{ cursor: 'pointer' }}>
                            <FontAwesomeIcon icon={showMap ? faAngleUp : faAngleDown} className='angicn' />
                        </div>
                    </div>
               
        </section>
    );
}

export default MakeAppointment;
