import React, { useState } from 'react'
import './Faqbanner.css'
import { Container } from 'react-bootstrap'
import Breadcrumbs from '../../CommonComponents/Breadcrumbs/Breadcrumbs';
function Faqbanner() {
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        { text: "Home", link: "/" },
        { text: "FAQs", link: "#" },
        ,
    ]);
    return (
        <>
            <section className='blogbanner_section'>
                <Container>
                    <div className='blogmain'>
                        <div>
                            <Breadcrumbs items={breadcrumbItems} />
                        </div>
                        <h2 className='blogtitle heading mb-0'>FAQs</h2>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Faqbanner