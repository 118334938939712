import React from 'react'
import './AboutBanner.css'
import { Container } from 'react-bootstrap'
function OurBanner() {
  return (
    <>
    <section className='blogbanner_section'>
        <Container>
            <div className='blogmain'>
                <h2 className='blogtitle heading mb-0'>Our Team</h2>
            </div>
        </Container>
    </section>
    </>
  )
}

export default OurBanner