import React from 'react'
import './FaqTabs.css'
import Contact from '../../Contact/Contact'
import { Accordion, Container, Nav, Tab } from 'react-bootstrap'
function FaqTabs() {

    const data = [
        {
            title: "What is Root Canal?",
            text: "Root canal treatment (also known as endodontic therapy, endodontic treatment, or root canal therapy) is a treatment sequence for the infected pulp of a tooth which is intended to result in the elimination of infection and the protection of the decontaminated tooth from future microbial invasion.[1] Root canals, and their associated pulp chamber, are the physical hollows within a tooth that are naturally inhabited by nerve tissue, blood vessels and other cellular entities. Together, these items constitute the dental pulp. Endodontic therapy involves the removal of these structures, disinfection and the subsequent shaping, cleaning, and decontamination of the hollows with small files and irrigating solutions, and the obturation (filling) of the decontaminated canals. Filling of the cleaned and decontaminated canals is done with an inert filling such as gutta- percha and typically a zinc oxide eugenol-based cement. Epoxy resin is employed to bind gutta-percha in some root canal procedures. Another option is to use an antiseptic filling material containing paraformaldehyde like N2. Endodontics includes both primary and secondary endodontic treatments as well as periradicular surgery which is generally used for teeth that still have potential for salvage.",
            eventkeyss: "0"
        },
        {
            title: "What are symptoms of cavity?",
            text: "Root canal treatment (also known as endodontic therapy, endodontic treatment, or root canal therapy) is a treatment sequence for the infected pulp of a tooth which is intended to result in the elimination of infection and the protection of the decontaminated tooth from future microbial invasion.[1] Root canals, and their associated pulp chamber, are the physical hollows within a tooth that are naturally inhabited by nerve tissue, blood vessels and other cellular entities. Together, these items constitute the dental pulp. Endodontic therapy involves the removal of these structures, disinfection and the subsequent shaping, cleaning, and decontamination of the hollows with small files and irrigating solutions, and the obturation (filling) of the decontaminated canals. Filling of the cleaned and decontaminated canals is done with an inert filling such as gutta- percha and typically a zinc oxide eugenol-based cement. Epoxy resin is employed to bind gutta-percha in some root canal procedures. Another option is to use an antiseptic filling material containing paraformaldehyde like N2. Endodontics includes both primary and secondary endodontic treatments as well as periradicular surgery which is generally used for teeth that still have potential for salvage.",
            eventkeyss: "1"
        },
        {
            title: "How is teeth whitening carried out?",
            text: "Root canal treatment (also known as endodontic therapy, endodontic treatment, or root canal therapy) is a treatment sequence for the infected pulp of a tooth which is intended to result in the elimination of infection and the protection of the decontaminated tooth from future microbial invasion.[1] Root canals, and their associated pulp chamber, are the physical hollows within a tooth that are naturally inhabited by nerve tissue, blood vessels and other cellular entities. Together, these items constitute the dental pulp. Endodontic therapy involves the removal of these structures, disinfection and the subsequent shaping, cleaning, and decontamination of the hollows with small files and irrigating solutions, and the obturation (filling) of the decontaminated canals. Filling of the cleaned and decontaminated canals is done with an inert filling such as gutta- percha and typically a zinc oxide eugenol-based cement. Epoxy resin is employed to bind gutta-percha in some root canal procedures. Another option is to use an antiseptic filling material containing paraformaldehyde like N2. Endodontics includes both primary and secondary endodontic treatments as well as periradicular surgery which is generally used for teeth that still have potential for salvage.",
            eventkeyss: "3"
        },
        {
            title: "How to fix gaps?",
            text: "Root canal treatment (also known as endodontic therapy, endodontic treatment, or root canal therapy) is a treatment sequence for the infected pulp of a tooth which is intended to result in the elimination of infection and the protection of the decontaminated tooth from future microbial invasion.[1] Root canals, and their associated pulp chamber, are the physical hollows within a tooth that are naturally inhabited by nerve tissue, blood vessels and other cellular entities. Together, these items constitute the dental pulp. Endodontic therapy involves the removal of these structures, disinfection and the subsequent shaping, cleaning, and decontamination of the hollows with small files and irrigating solutions, and the obturation (filling) of the decontaminated canals. Filling of the cleaned and decontaminated canals is done with an inert filling such as gutta- percha and typically a zinc oxide eugenol-based cement. Epoxy resin is employed to bind gutta-percha in some root canal procedures. Another option is to use an antiseptic filling material containing paraformaldehyde like N2. Endodontics includes both primary and secondary endodontic treatments as well as periradicular surgery which is generally used for teeth that still have potential for salvage.",
            eventkeyss: "4"
        },
    ]
    return (
        <>
            <section className='faqtabs_section'>
                <Container>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <div className='faq_main_div'>
                            <div className='faqconts'>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className='accord_section'>
                                        <Accordion defaultActiveKey="0">
                                            {data.map((data, index) => (
                                                <Accordion.Item eventKey={data.eventkeyss}>
                                                    <Accordion.Header className='accordheader'>{data.title}</Accordion.Header>
                                                    <Accordion.Body className='text'>
                                                        {data.text}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ))}
                                        </Accordion>

                                    </div>


                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                <div className='accord_section'>
                                        <Accordion defaultActiveKey="0">
                                            {data.map((data, index) => (
                                                <Accordion.Item eventKey={data.eventkeyss}>
                                                    <Accordion.Header className='accordheader'>{data.title}</Accordion.Header>
                                                    <Accordion.Body className='text'>
                                                        {data.text}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ))}
                                        </Accordion>

                                    </div>

                                </Tab.Pane>
                                <Tab.Pane eventKey="third">

                                <div className='accord_section'>
                                        <Accordion defaultActiveKey="0">
                                            {data.map((data, index) => (
                                                <Accordion.Item eventKey={data.eventkeyss}>
                                                    <Accordion.Header className='accordheader'>{data.title}</Accordion.Header>
                                                    <Accordion.Body className='text'>
                                                        {data.text}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ))}
                                        </Accordion>

                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                            </div>

                            <div className='tab_section'>
                                <Nav variant="pills" className="">
                                    <Nav.Item className='tabitemss me-3 mb-2'>
                                        <Nav.Link eventKey="first">DENTAL FAQs</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='tabitemss me-3 mb-2'>
                                        <Nav.Link eventKey="second">SKIN CARE FAQs</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='tabitemss mb-2'>
                                        <Nav.Link eventKey="third">HAIR TREATMENT FAQs</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>


                        </div>
                    </Tab.Container>
                </Container>
            </section>
        </>
    )
}

export default FaqTabs