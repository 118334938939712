import React, { useEffect } from 'react'
import "./Privacy_Policy.css"
import Privacy_Policy_Banner from './Privacy_Policy_Banner/Privacy_Policy_Banner'
import Privacy_Policy_Content from './Privacy_Policy_Content/Privacy_Policy_Content'

function Privacy_Policy() {

    // useEffect(() => {
    //     window.scrollTo(0, 0); // Scroll to the top when component mounts
    //   }, []);
  return (
<>
    <Privacy_Policy_Banner/>
    <Privacy_Policy_Content/>
</>
  )
}

export default Privacy_Policy