import React, { useState,useContext,useEffect } from 'react'
import './RecentBlog.css'
import { Link } from 'react-router-dom'
import { Context } from '../../../utils/context';
function RecentBlog({ data }) {

    const clickScroll= ()=> {
        window.scrollTo(0, 0);
    }

    
    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();    
    const { getData, postData, imageUrl } = useContext(Context);


    return (
        <>
            <div className='recentblog_div'>
                <div>
                <div className='recentimgdiv'>
                    <img className="recentimage" src={imageUrl + data?.image} alt="Logo" />
                </div>
                </div>
                <div className='recenttext'>
                    <h6 className='text'>{data?.title}</h6>
                    <Link to={`/bloginner/${data?.id}`} onClick={clickScroll}> <p className='readd mb-0'>READ MORE</p></Link>
                </div>

            </div>
        </>
    )
}

export default RecentBlog