import React from "react";
import "./Offer.css";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
function Offer() {
  return (
    <>
      <section className="offer_section">
        <Container>
          <div className="offer_main">
            <p className="text-sub">DELIGHTING OFFER</p>
            <h3 className="title-main mb-4">
              Upto <span className="digittext"> 25 </span> to{" "}
              <span className="digittext"> 50% </span> Off
            </h3>
            <p className="text mb-0">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
            <p className="text">Lorem Ipsum is simply dummy text.</p>
            <Link to={"https://wa.me/+918421054943"} target="_blank">
              {" "}
              <p className="text text-decoration-underline pt-4">
                BOOK AN APPOINTMENT
              </p>
            </Link>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Offer;
