import React, { useState,useContext,useEffect } from "react";
import "./ClientSays.css";
import { Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
// import { faAngleLeft, faAngleRight , faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../utils/context";
function ClientSays() {
  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  // const data = [
  //   {
  //     name: "Jacqueline Nathe",
  //     place: "Nashik",
  //     text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  //   },
  //   {
  //       name: "Nathe",
  //       place: "Nashik",
  //       text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  //     },
  // ];


  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const { getData, postData, imageUrl } = useContext(Context);
  const [testimonialsData, setTestimonialsData] = useState();

  const getTestimonials = async () => {
    const res = await getData(`/testimonialsGet`);
        if (res?.success) {
          setTestimonialsData(res.data.list);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }
  }

  useEffect(() => {
    getTestimonials();
}, []);


  return (
    <section className="clientsay_section pt-5">
      <Container>
        <div className="clientsay_div_main">
          <Row>
            <Col xxl={6} xl={6} lg={6} md={12}>
              <div className="clientsay_div">
                <p className="text-sub">TESTIMONIALS</p>
                <h3 className="title-main mb-4">
                  Check out <br />
                  what our <span className="highlight">clients say</span>
                </h3>
                <div className="clientsayimgdiv">
                  <img
                    className="clientsayimage"
                    src={process.env.PUBLIC_URL + "/assets/images/homepage/quama.png"}
                    alt="Logo"
                  />
                </div>
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={12} className="">
              <div className="clientsayswiper">
                <Swiper
                  className="mySwiper"
                  modules={[Pagination]}
                  pagination={{ clickable: true }}
                  onSwiper={(swiper) => setSwiperInstance(swiper)}
                >
                  {testimonialsData?.map((item, index) => (
                    <SwiperSlide key={index}>
                      <div className="testymentdiv">
                        <p className="testimonial-text">{item?.name}</p>
                        <p className="text-testimonial">{item?.location}</p>
                        <div className="border-main"></div>
                        <p className="p-text">{htmlToReactParser.parse(item?.descripation)}</p>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="slider-btns">
                  <div className="back-btn" onClick={() => handleMainSliderClick("prev")}>
                 
                  <FontAwesomeIcon icon={faArrowLeft} className="icon-main" />
                  </div>
                  <div className="next-btn" onClick={() => handleMainSliderClick("next")}>
                  <FontAwesomeIcon icon={faArrowRight} className="icon-main" />
                    
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
}

export default ClientSays;
