import React, { useEffect } from 'react'
import AboutBanner from './AboutBanner/AboutBanner'
import BookApointment from '../home/BookApointment/BookApointment'
import DrDetails from './DrDetails/DrDetails'
import Counter from '../CommonComponents/Counter/Counter'
import ExpertTeam from './ExpertTeam/ExpertTeam'
import Aboutsillm from './Aboutsillm/Aboutsillm'

function About() {
  // useEffect(() => {
  //   window.scrollTo(0, 0); // Scroll to the top when component mounts
  // }, []);
  return (
    <>
    <AboutBanner/>
    <Aboutsillm/>
    <Counter/>
    <ExpertTeam/>
    <BookApointment/>
    <DrDetails/>
    </>
  )
}

export default About