import React from "react";

function Terms_And_Condition_Content() {
  return (
    <>
      <section className="Privacy_Policy_Content">
        <div className="container">
          <div className="Privacy_Policy_Content_holder">
            <div className="list-holder">
              <div className="text-holder">
                <p>
                  By consenting to the terms on this site (the “Website”), you
                  are entering into the following Terms and Conditions
                  established with S'illume. PLEASE REVIEW THESE TERMS
                  THOUGHTFULLY. If you disagree, please leave the site and
                  refrain from further use.
                </p>
              </div>

              <div className="text-holder">
                <h3>1. Definitions</h3>
                <p>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s, when a unknown printer took a galley of type
                  and scrambled it to make a type specimen book.
                </p>

                <p>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s, when a unknown printer took a galley of type
                  and scrambled it to make a type specimen book.
                </p>

                <p>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s, when a unknown printer took a galley of type
                  and scrambled it to make a type specimen book.
                </p>
              </div>

              <div className="text-holder">
                <h3>2. Privacy</h3>
                <p>
                  By accessing this Website, you are accepting Mansa Nutri’s
                  Privacy Policy, the details of which can be found at
                  http://s'illume.com/privacy-policy/.
                </p>

                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </p>

                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </p>

                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Terms_And_Condition_Content;
