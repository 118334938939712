import React from 'react'
import './WhyChoose.css'
import { Container } from 'react-bootstrap'
function WhyChoose({treatmentData}) {

    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();
    return (
        <>
            <section className='whychoose_section'>
                <Container>
                    <div className='whychoose_main'>
                        <h3 className="title-main mb-4">
                            Why Choose The S'illume as a  <br />
                            Professional {treatmentData?.category?.name} for <span className="highlight"> {treatmentData?.subcategory?.name} in Pune?</span>
                        </h3>
                        <p className='text mb-4'>Here are some reasons why you should choose us for {treatmentData?.subcategory?.name}  in Pune</p>
                        <p className='text mb-1'>{htmlToReactParser?.parse(treatmentData?.whychooes)}.</p>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default WhyChoose