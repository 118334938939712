import React, { useEffect, useState, useContext } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { faPhone, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faHeart,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { Context } from "../../utils/context";

const Footer = () => {
  const { getData, postData, imageUrl } = useContext(Context);
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryID, setSubCategoryID] = useState([]);
  const [activeKey, setActiveKey] = useState(0);
  const [activeEventKey, setActiveEventKey] = useState(0);
  const [categoryID, setCategoryID] = useState(null);

  const getCategory = async () => {
    const res = await getData(`/category`);
    if (res?.success) {
      setCategoryData(res?.data?.list);
      setCategoryID(res?.data?.list[0]?.id);
    } else {
      console.error("Error fetching data:", res?.error);
    }
  };

  console.log(subCategoryID, "subCategoryIDsubCategoryIDsubCategoryID");
  const getSubCategory = async () => {
    try {
      const res = await getData(`/subcategory?category_id=${categoryID}`);
      if (res?.success) {
        setSubCategoryID(res?.data[0]?.id);
      } else {
        console.error("Error fetching data:", res?.error);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getSubCategory();
    getCategory();
  }, []);

  useEffect(() => {
    getSubCategory(categoryID);
  }, [categoryID]);

  const lnks = [
    {
      name: "Home",
      BaseLink: "/",
    },
    {
      name: "About Us",
      BaseLink: "/aboutus",
    },
    {
      name: "Treatments",
      BaseLink: `/treatments/${subCategoryID}`,
    },
    {
      name: "Our Team",
      BaseLink: "/ourteam",
    },
    {
      name: "Blog",
      BaseLink: "/blog",
    },
    {
      name: "Gallery",
      BaseLink: "/gallary",
    },
    {
      name: "Contact",
      BaseLink: "/contact",
    },
    {
      name: "FAQs",
      BaseLink: "/faqs",
    },
    {
      BaseLink: "/terms-and-condition",
      name: "Terms & Conditions",
    },
    {
      BaseLink: "/privacy-policy",
      name: "Privacy Policy",
    },
  ];

  return (
    <>
      <section className="footer_div">
        <Container>
          <div className="footermain_div">
            <div className="footer_top">
              {/* <Row className="footer-main">
                {lnks.map((text, index) => (
                  <Col xxl={1} xl={1} lg={2} md={3} sm={4} xs={6} className="">
                    <div className="top_div_footer">
                      <Link to={text.BaseLink}>
                        <p className="top_text">{text.name}</p>
                      </Link>
                    </div>
                  </Col>
                ))}
              </Row> */}

              <div className="d-flex justify-content-around flex-wrap">
                {lnks.map((text, index) => (
                  <div className="top_div_footer  ">
                    <Link to={text.BaseLink}>
                      <p className="top_text px-2">{text.name}</p>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            <div className="footer_miidle">
              <Row>
                <Col
                  xxl={5}
                  xl={6}
                  lg={7}
                  md={8}
                  sm={10}
                  xs={12}
                  className="mx-auto"
                >
                  <div className="top_div_footer">
                    <div className="addres_div padding_div">
                      <p className="top_text">
                        2nd Floor, Office No 3, M No. 734, Opp High Class
                        Society, Pashan Rd, near Suryadutta College, Patil
                        Nagar, Bavdhan, Pune, Maharashtra 411021
                      </p>
                    </div>
                    <div className="contact_div padding_div">
                      <p className="top_text">
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className="footericn"
                        />{" "}
                        testing@S'illume.com
                      </p>
                      <p className="top_text">
                        <FontAwesomeIcon icon={faPhone} className="footericn" />{" "}
                        +91 1000890984
                      </p>
                    </div>

                    <div className="all_icon padding_div">
                      {/* <FontAwesomeIcon icon={faFacebookF} className="cart-logo" /> */}
                      {/* <FontAwesomeIcon icon={faFacebook} /> */}
                      <Link to={"https://www.facebook.com/"} target="_blank">
                        <div className="headericn_div">
                          <FontAwesomeIcon
                            className="cart_logo"
                            icon={faFacebookF}
                          />
                        </div>
                      </Link>

                      <Link to={"https://www.instagram.com/"} target="_blank">
                        <div className="headericn_div">
                          {/* <FontAwesomeIcon
                            icon={faHeart}
                            className="cart_logo"
                          /> */}
                          <FontAwesomeIcon
                            icon={faInstagram}
                            className="cart_logo"
                          />
                        </div>
                      </Link>
                      <Link to={"https://www.youtube.com/"} target="_blank">
                        <div className="headericn_div">
                          {/* <FontAwesomeIcon
                            icon={faUser}
                            className="cart_logo"
                          /> */}
                          <FontAwesomeIcon
                            icon={faYoutube}
                            className="cart_logo"
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <p className="bottom_footer_text">
              © 2024 S'illume. All Rights Reserved. Developed by Profcyma
            </p>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Footer;
