import React, { useEffect, useState, useContext } from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import {
  faChevronDown,
  faShoppingCart,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Col, NavDropdown, Row, Tab } from "react-bootstrap";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import Accordion from "react-bootstrap/Accordion";
import { Context } from "../../utils/context";

function Header() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("home");

  useEffect(() => {
    const path = location.pathname.substring(1);
    setActiveLink(path || "home");
  }, [location.pathname]);

  const handleNavItemClick = (selectedLink) => {
    setActiveLink(selectedLink);
    setActiveKey(null);
    const navbarToggle = document.querySelector(".navbar-toggler");
    if (navbarToggle && window.innerWidth <= 992) {
      navbarToggle.click();
    }
  };

  const treatments = [
    {
      name: "Dental Fillings",
    },
    {
      name: "Root Canal Treatment",
    },
    {
      name: "Orthodontic Treatment (Invisalign, Aligners, Braces)",
    },
    {
      name: "Dental Crown & Bridges",
    },
    {
      name: "Tooth Extraction / Tooth Removal",
    },
    {
      name: "Pediatrics Dental Treatments",
    },
    {
      name: "Acne & Acne Scar Treatment",
    },
    {
      name: "Tan Removal Treatment",
    },
    {
      name: "Moles Removal",
    },
    {
      name: "Pre - Bridal Treatment",
    },
    {
      name: "Hydra Facial",
    },
  ];

  const { getData, postData, imageUrl } = useContext(Context);
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [activeKey, setActiveKey] = useState(0);
  const [activeEventKey, setActiveEventKey] = useState(0);
  const [categoryID, setCategoryID] = useState(null);

  const getCategory = async () => {
    const res = await getData(`/category`);
    if (res?.success) {
      setCategoryData(res?.data?.list);
      setCategoryID(res?.data?.list[0]?.id);
    } else {
      console.error("Error fetching data:", res?.error);
    }
  };

  const getSubCategory = async (category_id) => {
    try {
      const res = await getData(`/subcategory?category_id=${category_id}`);
      if (res?.success) {
        setSubCategoryData(res?.data);
      } else {
        console.error("Error fetching data:", res?.error);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleItemClick = (category_id, index) => {
    getSubCategory(category_id);
    setActiveEventKey(index);
  };

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    if (categoryID) {
      getSubCategory(categoryID);
    }
  }, [categoryID]);

  return (
    <>
      <section className="second-header-sec">
        <Container>
          <Navbar expand="lg">
            <Navbar.Brand as={Link} to="/">
              <img
                className="headlogo"
                src={
                  process.env.PUBLIC_URL + "/assets/images/icons/headerlogo.png"
                }
                alt="Logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav ">
              <Nav className="">
                <Nav.Link
                  as={Link}
                  to="/"
                  className={location.pathname === "/" ? "active" : ""}
                  onClick={handleNavItemClick}
                >
                  Home
                </Nav.Link>
                {/* <Nav.Link
                  as={Link}
                  to="/aboutus"
                  className={location.pathname === "/aboutus" ? "active" : ""}
                  onClick={handleNavItemClick}
                >
                  About Us
                </Nav.Link> */}

                <NavDropdown
                  className="deskdroppp"
                  title={
                    <>
                      {" "}
                      Treatments <FontAwesomeIcon icon={faChevronDown} />{" "}
                    </>
                  }
                  id="basic-nav-dropdown"
                >
                  <div>
                    <Tab.Container
                      id="left-tabs-example"
                      activeKey={activeEventKey}
                    >
                      <Row>
                        <Col xxl={4} xl={4} lg={5} md={12} className="dpq">
                          <div className="droprows1">
                            <Nav
                              variant="pills"
                              className="tabbmains flex-column"
                            >
                              {categoryData?.map((data, index) => (
                                <Nav.Item className="treattabitem" key={index}>
                                  <Nav.Link
                                    className="treattablink"
                                    eventKey={index}
                                    onClick={() =>
                                      handleItemClick(data?.id, index)
                                    }
                                  >
                                    {data?.name}
                                  </Nav.Link>
                                </Nav.Item>
                              ))}
                            </Nav>
                          </div>
                        </Col>
                        <Col xxl={8} xl={8} lg={7} md={12}>
                          <div className="droprows2">
                            <Tab.Content>
                              <Tab.Pane eventKey={activeEventKey}>
                                <Row>
                                  {subCategoryData?.map((treatment, index) => (
                                    <Col
                                      xxl={6}
                                      xl={6}
                                      lg={6}
                                      md={6}
                                      key={index}
                                    >
                                      {treatment?.name ===
                                        "Orthodontic Treatment" ||
                                      treatment?.name ===
                                        "Semi- Permanent  Makeup" ||
                                      treatment?.name ===
                                        "Smile Designing & Makeover" ? (
                                        <Link
                                          to={`/treatmentcategory/${treatment?.id}`}
                                        >
                                          <p className="treatname_text">
                                            {treatment?.name}
                                          </p>
                                        </Link>
                                      ) : (
                                        <Link
                                          to={`/treatments/${treatment?.id}`}
                                        >
                                          <p className="treatname_text">
                                            {treatment?.name}
                                          </p>
                                        </Link>
                                      )}
                                    </Col>
                                  ))}
                                </Row>
                              </Tab.Pane>
                            </Tab.Content>
                          </div>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>
                </NavDropdown>

                <div className="mobdroppp">
                  <Accordion
                    activeKey={activeKey}
                    onSelect={(k) => setActiveKey(k)}
                  >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="accordheaderr">
                        {" "}
                        Treatments
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <Tab.Container
                            id="left-tabs-example"
                            activeKey={activeEventKey}
                          >
                            <Row>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={5}
                                md={12}
                                className="dpq"
                              >
                                <div className="droprows1">
                                  <Nav
                                    variant="pills"
                                    className="tabbmains flex-column"
                                  >
                                    {categoryData?.map((data, index) => (
                                      <Nav.Item
                                        className="treattabitem"
                                        key={index}
                                      >
                                        <Nav.Link
                                          className="treattablink"
                                          eventKey={index}
                                          onClick={() =>
                                            handleItemClick(data?.id, index)
                                          }
                                        >
                                          {data?.name}
                                        </Nav.Link>
                                      </Nav.Item>
                                    ))}
                                  </Nav>
                                </div>
                              </Col>
                              <Col xxl={8} xl={8} lg={7} md={12}>
                                <div className="droprows2 drppp">
                                  <Tab.Content>
                                    <Tab.Pane eventKey={activeEventKey}>
                                      <Row>
                                        {subCategoryData?.map(
                                          (treatment, index) => (
                                            <Col
                                              xxl={6}
                                              xl={6}
                                              lg={6}
                                              md={6}
                                              key={index}
                                            >
                                              {treatment?.name ===
                                                "Orthodontic Treatment" ||
                                              treatment?.name ===
                                                "Smile Designing & Makeover" ||
                                              treatment?.name ===
                                                "Semi-Permanent Makeup" ? (
                                                <Link
                                                  to={`/treatmentcategory/${treatment?.id}`}
                                                >
                                                  <p className="treatname_text">
                                                    {treatment?.name}
                                                  </p>
                                                </Link>
                                              ) : (
                                                <Link
                                                  to={`/treatments/${treatment?.id}`}
                                                >
                                                  <p className="treatname_text">
                                                    {treatment?.name}
                                                  </p>
                                                </Link>
                                              )}
                                            </Col>
                                          )
                                        )}
                                      </Row>
                                    </Tab.Pane>
                                  </Tab.Content>
                                </div>
                              </Col>
                            </Row>
                          </Tab.Container>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>

                <Nav.Link
                  as={Link}
                  to="/ourteam"
                  className={location.pathname === "/ourteam" ? "active" : ""}
                  onClick={handleNavItemClick}
                >
                  Our Team
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/blog"
                  className={location.pathname === "/blog" ? "active" : ""}
                  onClick={handleNavItemClick}
                >
                  Blog
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/gallary"
                  className={location.pathname === "/gallary" ? "active" : ""}
                  onClick={handleNavItemClick}
                >
                  Gallary
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/contact"
                  className={location.pathname === "/contact" ? "active" : ""}
                  onClick={handleNavItemClick}
                >
                  Contact
                </Nav.Link>
              </Nav>
              <div className="all-icon">
                <Link
                  to={"https://www.facebook.com/"}
                  target="_blank"
                  onClick={handleNavItemClick}
                >
                  <div className="headericn_div">
                    <FontAwesomeIcon className="cart_logo" icon={faFacebookF} />
                  </div>
                </Link>

                <Link
                  to={"https://www.instagram.com/"}
                  target="_blank"
                  onClick={handleNavItemClick}
                >
                  <div className="headericn_div">
                    <FontAwesomeIcon icon={faInstagram} className="cart_logo" />
                  </div>
                </Link>
                <Link
                  to={"https://www.youtube.com/"}
                  target="_blank"
                  onClick={handleNavItemClick}
                >
                  <div className="headericn_div">
                    <FontAwesomeIcon icon={faYoutube} className="cart_logo" />
                  </div>
                </Link>
              </div>
            </Navbar.Collapse>
          </Navbar>

          <Link to={"https://wa.me/+918421054943"} target="_blank">
            <div className="commonlogodiv">
              <img
                className="commonlogo"
                src={
                  process.env.PUBLIC_URL + "/assets/images/icons/whatsapp.png"
                }
                alt="Logo"
              />
            </div>
          </Link>
        </Container>
      </section>
    </>
  );
}

export default Header;
