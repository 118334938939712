import React,{useContext} from 'react'
import './TeethStain.css'
import { Col, Container, Row } from 'react-bootstrap'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import ServiceCard from '../../CommonComponents/ServiceCard/ServiceCard';
import { Context } from '../../../utils/context';
function TeethStain({treatmentData}) {

    
  const { getData, postData, imageUrl } = useContext(Context);
    const sellercardsDetails = [
        {
            id: 1,
            title: "Dental Services",
            content: "Stains that are inside your tooth enamel are called intrinsic stains. Intrinsic staining is sometimes present even before your teeth erupt from your gums when you’re a kid.",
            content2:" These stains can result from antibiotic use, high levels of fluoride exposure, and your tooth enamel growing thinner as you age. Intrinsic staining can sometimes even be genetic, according to research.",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/srvice1.png",
            detailsLink: "/product",
        },
        {
            id: 2,
            title: "Skin Treatment",
            content: "Extrinsic stains are on the outside of your tooth. These happen due to environmental exposure to things that leave discoloration on your tooth enamel. Coffee, artificial food colorings, and smoking can all cause this type of staining. ",
           
            content2:"Like intrinsic stains, extrinsic stains can also be linked to antibiotic use, based research.",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service2.png",
            detailsLink: "",
        },
        {
            title: "Hair Treatment",
            content: "Extrinsic stains are on the outside of your tooth. These happen due to environmental exposure to things that leave discoloration on your tooth enamel. Coffee, artificial food colorings, and smoking can all cause this type of staining. ",
           
            content2:"Like intrinsic stains, extrinsic stains can also be linked to antibiotic use, based research.",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
        },
       
    ];

    return (
        <>
            <section className='teethswiper_section'>
                <Container>
                <h3 className="title-main mb-5">
                            Types of <br />
                            <span className="highlight"> {treatmentData?.subcategory?.name} </span>
                        </h3>
                    <div className='teeth_swiper_main'>
                        <Swiper
                         breakpoints={{
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            486: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            992: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1200: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },
                            1400: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },
                            1900: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },
                        }}
                            className="mySwiper"
                           
                            modules={[Pagination]}
                            pagination={{ clickable: true ,dynamicBullets: true, }}
                        //   onSwiper={(swiper) => setSwiperInstance(swiper)}
                        >
                            {treatmentData?.treattype?.map((data, index) => (
                                <SwiperSlide key={index}>
                                   <div className='carrrd_data'>
                                            <h1 className='card-title mb-3'>{data.title}</h1>
                                            <div className='servc'>
                                                <Row>
                                                    <Col xxl={5} xl={5} lg={5} md={6} sm={6} xs={12}>
                                                        <div className='treatcardimage_div mb-3'>
                                                            <img src={imageUrl + data?.typeimage} className='treatcardimage' />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={7} xl={7} lg={7} md={6} sm={6} xs={12}>
                                                        <div className='servctext'>
                                                            <p className='small_text '>{data?.typetitle}</p>
                                                            <p className='small_text '>{data?.typedescription}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>

                    </div>
                </Container>
            </section>
        </>
    )
}

export default TeethStain