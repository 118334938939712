// import React from 'react'
// import './OurDiscription.css'
import "./OurDiscription.css";
import "./Ourdescription1.css";
import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlay,
  faCircleXmark,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import { gsap } from "gsap";
import { Link } from "react-router-dom";
function OurDiscription3() {
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const vidRef = useRef(null);
  const playCircle1 = useRef(null);
  const playCircle2 = useRef(null);
  const playPerspective = useRef(null);
  const playVideo = useRef(null);
  const playTriangle = useRef(null);
  const playBackdrop = useRef(null);
  const playClose = useRef(null);

  const handlePlayButtonClick = () => {
    setIsVideoVisible(true);
    setIsClicked(true);
  };

  const handleCloseButtonClick = () => {
    setIsVideoVisible(false);
    setIsClicked(false);
  };

  useEffect(() => {
    gsap.set(playCircle1.current, {
      rotation: 90,
      transformOrigin: "center",
    });

    gsap.set(playCircle2.current, {
      rotation: -90,
      transformOrigin: "center",
    });

    gsap.set(playPerspective.current, {
      xPercent: 6.5,
      scale: 0.175,
      transformOrigin: "center",
      perspective: 1,
    });

    gsap.set(playVideo.current, {
      visibility: "hidden",
      opacity: 0,
    });

    gsap.set(playTriangle.current, {
      transformOrigin: "left center",
      transformStyle: "preserve-3d",
      rotationY: 10,
      scaleX: 2,
    });

    const rotateTL = gsap
      .timeline({ paused: true })
      .to(playCircle1.current, {
        opacity: 0.1,
        rotation: "+=360",
        strokeDasharray: "456 456",
        duration: 0.7,
        ease: "power1.inOut",
      })
      .to(
        playCircle2.current,
        {
          opacity: 0.1,
          rotation: "-=360",
          strokeDasharray: "411 411",
          duration: 0.7,
          ease: "power1.inOut",
        },
        0
      );

    const openTL = gsap
      .timeline({ paused: true })
      .to(
        playBackdrop.current,
        {
          opacity: 0.95,
          visibility: "visible",
          duration: 1,
          ease: "power2.inOut",
        },
        0
      )
      .to(
        playClose.current,
        { opacity: 1, duration: 1, ease: "power2.inOut" },
        0
      )
      .to(
        playPerspective.current,
        { xPercent: 0, scale: 1, duration: 1, ease: "power2.inOut" },
        0
      )
      .to(
        playTriangle.current,
        { scaleX: 1, duration: 1, ease: "expoScale(2, 1, power2.inOut)" },
        0
      )
      .to(
        playTriangle.current,
        {
          rotationY: 0,
          duration: 1,
          ease: "expoScale(10, 0.01, power2.inOut)",
        },
        0
      )
      .to(
        playVideo.current,
        { visibility: "visible", opacity: 1, duration: 1 },
        0.5
      );

    const button = document.querySelector(".main-play-btn");
    const backdrop = document.querySelector(".play-backdrop");
    const close = document.querySelector(".play-close");

    if (button && backdrop && close) {
      button.addEventListener("mouseover", () => rotateTL.play());
      button.addEventListener("mouseleave", () => rotateTL.reverse());
      button.addEventListener("click", () => openTL.play());
      backdrop.addEventListener("click", () => openTL.reverse());
      close.addEventListener("click", (e) => {
        e.stopPropagation();
        openTL.reverse();
      });
    }
  }, []);

  useEffect(() => {
    if (vidRef.current) {
      if (isVideoVisible) {
        vidRef.current.play();
      } else {
        vidRef.current.pause();
      }
    }
  }, [isVideoVisible]);

  return (
    <section className={`my-4 Specialist `}>
      <Container>
        <div className={`nonvdo ${isClicked ? "clicked" : "notclicked"}`}>
          <Row className="reversee">
            <Col xxl={6} xl={6} lg={6} md={6}>
              <div className="specialist-main">
                <img
                  className="specialist-image"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/OurTeam/drs.png"
                  }
                  alt="Specialist"
                />
                <div className="main-play-btn" onClick={handlePlayButtonClick}>
                  <div className="play-button-container">
                    <div className="main-circle">
                      <div className="play-backdrop" ref={playBackdrop}></div>

                      <div className="play-button">
                        <div className="aaaa">
                          <button className="clickkme">
                            <FontAwesomeIcon
                              icon={faCirclePlay}
                              className="fcircleee"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col xxl={6} xl={6} lg={6} md={6} className="my-auto">
              <div className="content">
                <p className="text-sub">Dr. Mrunalini Zoting</p>
                <p className="text-mini">MBBS, DDV</p>

                <p className="p-text pt-5">
                  Dr Aniket board certified dermatologist, trichologist and
                  aesthetic dermatologist. He is vastly experienced in diagnosis
                  and treatment of various skin, hair, nail disorders and STIs,
                  He also specialises in aesthetic treatments like peels for
                  acne, pigmentation, scar, PRP treatment for hair loss,
                  anti-ageing treatments. He has expertise encompassing a wide
                  range of services aimed at maintaining skin health and
                  addressing various dermatological concerns.
                </p>
                {/* <div className="apointsec_div">
                  <Link to={"https://wa.me/+918421054943"} target="_blank">
                    {" "}
                    <p className="text pt-3">BOOK AN APPOINTMENT</p>
                  </Link>
                </div> */}
              </div>
            </Col>
          </Row>
        </div>

        <div className={`videoss ${!isClicked ? "clicked" : "notclicked"}`}>
          {/* <p className="text-sub">DR. PAYAS NIMJE</p>
          <p className="text-mini">BDS, PGDCC Clinical Cosmetology</p> */}
          <div className="video">
            <video
              src={process.env.PUBLIC_URL + "/assets/videos/Japan.mp4"}
              ref={vidRef}
              muted
              loop
              className="video-class-grid"
            />
            <div className="close" onClick={handleCloseButtonClick}>
              <FontAwesomeIcon icon={faCircleXmark} />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default OurDiscription3;
