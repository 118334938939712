import React, { useState, useContext, useEffect } from 'react'
import './BlogInner.css'
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap'
import RecentBlog from '../RecentBlog/RecentBlog';
import { Context } from '../../../utils/context'
import Breadcrumbs from '../../CommonComponents/Breadcrumbs/Breadcrumbs';
function BlogInner() {

    const { id } = useParams();



    const recent = [
        {
            id: 1,
            content: "Now hair treatment is no more a headache",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/srvice1.png",
            title: "Dental Services",
            detailsLink: "/bloginner",
        },
        {
            id: 2,
            content: "Now hair treatment is no more a headache",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service2.png",
            title: "Dental Services",
            detailsLink: "/bloginner",
        },
        {
            content: "Now hair treatment is no more a headache",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            detailsLink: "/bloginner",
        },
        {
            content: "Now hair treatment is no more a headache",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            title: "Dental Services",
        },
        {
            content: "Now hair treatment is no more a headache",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            title: "Dental Services",
            detailsLink: "/bloginner",
        },
        {
            content: "Now hair treatment is no more a headache",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            title: "Dental Services",
            detailsLink: "/bloginner",
        },
        {
            content: "Now hair treatment is no more a headache",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            title: "Dental Services",
            detailsLink: "/bloginner",
        },
        {
            content: "Now hair treatment is no more a headache",
            image:
                process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            title: "Dental Services",
            detailsLink: "/bloginner",
        },
    ];



    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();

    const { getData, postData, imageUrl } = useContext(Context);
    const [blogDetailsData, setBlogDetailsData] = useState();
    const [blogData, setBlogData] = useState([]);

    const getBlogDetails = async () => {
        const res = await getData(`/blogdetails?id=${id}`);
        if (res?.success) {
            setBlogDetailsData(res.data.list);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }
    }


    const getBlog = async () => {
        const res = await getData(`/blog`);
        if (res?.success) {
            setBlogData(res.data.list.data);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getBlog();
    }, []);

    const [breadcrumbItems, setbreadcrumbItems] = useState([
        { text: "Home", link: "/" },
        { text: "blog", link: "/blog" },
        { text: blogDetailsData?.title, link: "#" },
        ,
    ]);

    useEffect(() => {
        getBlogDetails();
    }, [id]);

    return (
        <>

            <section className='blogmain_section py-5'>
                <Container>
                    <div className='blogmain_div'>
                        <div className='mb-4'>
                            <Breadcrumbs items={breadcrumbItems} />
                        </div>
                        <Row>
                            <Col xxl={8} xl={8} lg={8} md={12} className='mb-3'>
                                <div className='blogmaincont1'>
                                    <div className='bloginner_main'>
                                        <div className='bloginnerimgdiv mb-3'>
                                            <img className="bloginnerimage" src={imageUrl + blogDetailsData?.image} alt="Logo" />
                                        </div>
                                        <h6 className='subtitle'>{blogDetailsData?.title}</h6>
                                        {htmlToReactParser.parse(blogDetailsData?.description)}
                                    </div>
                                </div>
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={12} className='mb-3'>
                                <div className='blogmaincont2 '>

                                    <div className='blogrecent mb-4'>
                                        {blogData?.map((recent, index) => (
                                            <div className='mb-3'>
                                                <RecentBlog data={recent} />
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </Col>
                        </Row>

                    </div>
                </Container>
            </section>
        </>
    )
}

export default BlogInner