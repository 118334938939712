import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import Breadcrumbs from '../../CommonComponents/Breadcrumbs/Breadcrumbs';

function GallaryBanner() {

    const [breadcrumbItems, setbreadcrumbItems] = useState([
        { text: "Home", link: "/" },
        { text: "Gallery", link: "#" },
        ,
    ]);
    return (
        <>
            <section className='blogbanner_section'>
                <Container>
                    <div className='blogmain'>
                        <div>
                            <Breadcrumbs items={breadcrumbItems} />
                        </div>
                        <h2 className='blogtitle heading mb-0'>Gallery</h2>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default GallaryBanner