import React, { useEffect } from "react";
import "./App.css";
import "./index.css";

import { Route, Routes } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./components/home/Home";
import Blog from "./components/Blog/Blog";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import OurTeam from "./components/OurTeam/OurTeam";
import Faqs from "./components/Faqs/Faqs";
import Galary from "./components/Galary/Galary";
import AppContext from "./utils/context.js";
import Privacy_Policy from "./components/Privacy_Policy/Privacy_Policy";
import Terms_And_Condition from "./components/Terms_And_Condition/Terms_And_Condition";
import BlogInner from "./components/Blog/BlogInner/BlogInner";
import Treatments from "./components/Treatments/Treatments";
import ScrollToTop from "./components/ScrollTotop/ScrollTotop";
import TreatmentCategory from "./components/TreatmentCategory/TreatmentCategory.js";

function App() {
  // useEffect(() => {
  //   window.scrollTo(0, 0); 
  // }, []);
  return (
    <>
      <AppContext>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/bloginner/:id" element={<BlogInner />} />
        {/* <Route path="/aboutus" element={<About />} /> */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/ourteam" element={<OurTeam />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/gallary" element={<Galary />} />
        <Route path="/privacy-policy" element={<Privacy_Policy />} />
        <Route path="/treatments/:id" element={<Treatments />} />
        <Route path="/terms-and-condition" element={<Terms_And_Condition />} />
        <Route path="/treatmentcategory/:id" element={<TreatmentCategory />} />
      </Routes>
      <Footer />
      </AppContext>
    </>
  );
}

export default App;
