import React,{useEffect,useContext,useState} from 'react'
import './ServiceCard.css'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import AllDetailModal from '../AllDetailModal/AllDetailModal'
import { Context } from '../../../utils/context'


function ServiceCard3({data , icons, onclick}) {


  const { getData, postData, imageUrl } = useContext(Context);
  const iconStyle = {
    fontSize: icons === 'READ MORE' ? '12px' : '20px'
  };

  
  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();

  
  return (
    <>
    <div className='servicecard_main serviceeeee'>
        <div className='servicecardimage_div mb-3'>
            <img src={imageUrl +  data?.image} className='servicecardimage'/>
        </div>
        <div className='sertitlediv'>
        <h1 className='carditle '> {data?.title}</h1>
        </div>
        <div className='content_divss'>
        <p className='small_text '>{htmlToReactParser?.parse(data?.description)}</p>
        </div>
        <div className=''>
        <Link to={data.detailsLink} className='serviceicn' onClick={onclick} style={iconStyle}>{icons}</Link>
        </div>

    </div>


     
    
    </>
  )
}

export default ServiceCard3