import React, { useState } from "react";
import "./Terms_And_Condition_Banner.css"
import Breadcrumbs from "../../CommonComponents/Breadcrumbs/Breadcrumbs";

function Terms_And_Condition_Banner() {
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    { text: "Terms and conditions", link: "#" },
    ,
  ]);
  return (
    <>
      <section className=" Privacy_Policy Terms_And_Condition_Banner">
        <div className="Banner_holder">
          <div className="container">
            <div className="text-holder ">
              <div>
                <Breadcrumbs items={breadcrumbItems} />
              </div>
              <h1 className="mb-5 heading">Terms and Conditions</h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Terms_And_Condition_Banner;
