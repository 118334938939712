import React from "react";
import "./BookApointment.css";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
function BookApointment() {
  return (
    <>
      <section className="bookapoint_section">
        <Container>
          <div className="bookapoint_div_main">
            <div className="apoint1_div">
              <p className="text-sub">APPOINTMENT</p>
              <h3 className="title-main">
                Want to lock in <br /> your spot or{" "}
                <span className="highlight ">need answers?</span>
              </h3>
            </div>
            <div className="apointsec_div">
              <Link  to={"https://wa.me/+918421054943"} target="_blank">
                {" "}
                <p className="text">BOOK AN APPOINTMENT</p>
              </Link>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default BookApointment;
