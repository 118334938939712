import React,{useContext,useEffect,useState} from 'react'
import './ServiceCard.css'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Context } from '../../../utils/context'
function ServiceCard2({data , icons}) {

  const iconStyle = {
    fontSize: icons === 'READ MORE' ? '12px' : '20px'
  };


  
  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();
  
  const { getData, postData, imageUrl } = useContext(Context);

  return (
    <>
    <div className='servicecard_main'>
        <div className='servicecardimage_div mb-3'>
            <img src={imageUrl + data?.image} className='servicecardimage'/>
        </div>
        <div className='sertitlediv'>
        <h1 className='carditle'>{data?.title}</h1>
        </div>
        <div className='content_divss'>
        <p className='small_text '>{htmlToReactParser.parse(data?.description)}</p>
        </div>
        <div className=''>
        <Link to={`/bloginner/${data?.id}`} className='serviceicn' style={iconStyle}>{icons}</Link>
        </div>

    </div>
    
    </>
  )
}

export default ServiceCard2