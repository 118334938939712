import React, { useEffect, useState } from "react";
import "./Contact.css";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { Col, Container, Form, Row } from "react-bootstrap";
import {
  faAngleDown,
  faCalendarTimes,
  faClock,
  faEnvelope,
  faLocationDot,
  faMapLocationDot,
  faPhone,
  faTimeline,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Contact() {
  const [phoneValue, setPhoneValue] = useState("");

  // useEffect(() => {
  //     window.scrollTo(0, 0); // Scroll to the top when component mounts
  //   }, []);
  // const [showMap, setShowMap] = useState(false);
  const [showMap, setShowMap] = useState(false);

  const toggleMap = () => {
    setShowMap(!showMap);
  };

  return (
    <>
      <section className="cont_sectionn">
        <div className={`contact_section `}>
          <Container>
            <div className="contact_main">
              <h2 className="contactus heading">Contact Us</h2>
              <div>
                <Row>
                  <Col xxl={8} xl={8} lg={9} md={11} sm={12}>
                    <div>
                      <Row>
                        <Col
                          xxl={8}
                          xl={8}
                          lg={7}
                          md={6}
                          sm={12}
                          className=" pe-xl-0 pe-xxl-0 pe-lg-0  pe-md-0"
                        >
                          <div className="leftdivv">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Your Name"
                                />
                              </Form.Group>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  type="email"
                                  placeholder="Email"
                                />
                              </Form.Group>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Subject"
                                />
                              </Form.Group>
                              <PhoneInput
                                className="mb-3"
                                country={"in"}
                                value={phoneValue}
                                onChange={setPhoneValue}
                                inputProps={{
                                  name: "phone",
                                  required: true,
                                  autoFocus: true,
                                }}
                              />
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1"
                              >
                                <Form.Control
                                  as="textarea"
                                  rows={1}
                                  placeholder="Your Message"
                                />
                              </Form.Group>
                            </Form>
                            <div className="btndiv">
                              <button className="btnnnn">Send Message</button>
                            </div>
                          </div>
                        </Col>
                        <Col
                          xxl={4}
                          xl={4}
                          lg={5}
                          md={6}
                          sm={12}
                          className="ps-xxl-0 ps-xl-0 ps-lg-0 ps-md-0"
                        >
                          <div className="leftdivv1">
                            <div className="d-flex align-items-center mt-3">
                              <div>
                                <div className="fonticndiv">
                                  <FontAwesomeIcon icon={faPhone} />
                                </div>
                              </div>
                              <p className="cont_text mb-0 ms-2">8421054943</p>
                            </div>
                            <div className="d-flex align-items-center mt-3">
                              <div>
                                <div className="fonticndiv">
                                  <FontAwesomeIcon icon={faEnvelope} />
                                </div>
                              </div>
                              <p className="cont_text mb-0 ms-2">
                                Sillume.clinics.pune
                              </p>
                            </div>
                            <div className="d-flex align-items-center mt-3">
                              <div>
                                <div className="fonticndiv">
                                  <FontAwesomeIcon icon={faLocationDot} />
                                </div>
                              </div>
                              <p className="cont_text mb-0 ms-2 ">
                                305, 46 downtown, <br />
                                pashan sus bridge, <br /> Near pizza hut, <br />{" "}
                                Mohan Nagar Co-Oprative Society, baner,
                                <br /> Pune-411045{" "}
                              </p>
                            </div>

                            <div className="d-flex align-items-center mt-3">
                              <div>
                                <div className="fonticndiv">
                                  <FontAwesomeIcon icon={faClock} />
                                </div>
                              </div>
                              <div>
                                <div>
                                  <p className="cont_text mb-2 ms-2 ">
                                    {" "}
                                    <b>Morning:-</b> 10 am - 1:30 pm{" "}
                                  </p>
                                </div>
                                <div>
                                  <p className="cont_text mb-2 ms-2 ">
                                    {" "}
                                    <b>Evening:-</b> 6 pm - 8 pm{" "}
                                  </p>
                                </div>
                                <div>
                                  <p className="cont_text mb-2 ms-2 ">
                                    {" "}
                                    <b>Morning:-</b> 10 am - 1:30 pm{" "}
                                  </p>
                                </div>
                                <div>
                                  <p className="cont_text mb-2 ms-2 ">
                                    {" "}
                                    <b>Sunday:-</b> appointment basis
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center mt-3">
                              <div>
                                <div className="fonticndiv">
                                  <FontAwesomeIcon icon={faMapLocationDot} />
                                </div>
                              </div>
                              <p
                                className="cont_text mb-0 ms-2 text-decoration-underline curser-pointer"
                                onClick={toggleMap}
                              >
                                Find Us on map
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </div>
        {/* <div className={`map_section mapsec2 ${showMap ? 'visible' : 'hidden'}`}> */}

        <div className={`gmap_canvas ${showMap ? "show" : ""}`}>
          <iframe
            className="gmap_iframe"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=305, 46 Downtown, Pashan Sus Bridge, Near Pizza Hut, Mohan Nagar Co-Operative Society, Baner, Pune-411045&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ></iframe>
          <div
            className="angleee"
            onClick={toggleMap}
            style={{ cursor: "pointer" }}
          >
            <FontAwesomeIcon icon={faAngleDown} className="angleicnss" />
          </div>
        </div>

        {/* </div> */}
      </section>
    </>
  );
}

export default Contact;
