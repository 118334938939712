import React, { useEffect, useState, useContext } from "react";
import "./DentalFalling.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
function DentalFalling({ treatmentData }) {
  const { getData, postData, imageUrl } = useContext(Context);
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  return (
    <>
      <section className="dentfall_section">
        <Container>
          <div>
            <Row className="mb-3">
              <Col xxl={6} xl={6} lg={6} md={12}>
                <div>
                  <h3 className="dentaltext">{treatmentData?.title}</h3>
                  <p className="text">
                    {htmlToReactParser.parse(treatmentData?.sub_title)}
                  </p>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xxl={6} xl={6} lg={6} md={12}>
                <div className="fillingmain_div">
                  <p className="text">
                    {htmlToReactParser.parse(treatmentData?.descripation)}
                  </p>
                  <Link to={"https://wa.me/+918421054943"} target="_blank">
                    {" "}
                    <p className="bookapoint  text-decoration-underline">
                      BOOK AN APPOINTMENT
                    </p>
                  </Link>
                </div>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={12}>
                <div className="fallingimgdiv">
                  <img
                    className="fallingimage"
                    src={imageUrl + treatmentData?.image}
                    alt="Specialist"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}

export default DentalFalling;
