import React, { useContext, useEffect, useState } from 'react'
import './GallarySwiper.css'
import { Col, Container, Row } from 'react-bootstrap'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Context } from '../../../utils/context';
function GallarySwiper() {
    const data = [
        {
            image1: process.env.PUBLIC_URL + "/assets/images/Gallary/hospital _room.png",
            image2: process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            image3: process.env.PUBLIC_URL + "/assets/images/homepage/srvice1.png",
            image4: process.env.PUBLIC_URL + "/assets/images/homepage/service2.png",
            image5: process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
        },
        {
            image1: process.env.PUBLIC_URL + "/assets/images/Gallary/hospital _room.png",
            image2: process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            image3: process.env.PUBLIC_URL + "/assets/images/homepage/srvice1.png",
            image4: process.env.PUBLIC_URL + "/assets/images/homepage/service2.png",
            image5: process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
        },
        {
            image1: process.env.PUBLIC_URL + "/assets/images/Gallary/hospital _room.png",
            image2: process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
            image3: process.env.PUBLIC_URL + "/assets/images/homepage/srvice1.png",
            image4: process.env.PUBLIC_URL + "/assets/images/homepage/service2.png",
            image5: process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
        },
    ]
    const data2 = [
        {
            images: process.env.PUBLIC_URL + "/assets/images/homepage/srvice1.png",
        },
        {
            images: process.env.PUBLIC_URL + "/assets/images/homepage/service2.png",
        },
        {
            images: process.env.PUBLIC_URL + "/assets/images/homepage/service3.png",
        },
        {
            images: process.env.PUBLIC_URL + "/assets/images/homepage/srvice1.png",
        },
    ]



    const { getData, postData, imageUrl } = useContext(Context);
    const [gallerydata, setGallaryData] = useState();


    const getGallery = async () => {
        const res = await getData(`/gallery`);
        if (res?.success) {
            let images = [];
            let temp = [];
            console.log(images.length);
            for (const val of res?.data?.list) {
                if (temp.length === 5) {
                    images.push(temp.slice());
                    temp = [];
                }
                temp.push(val);
            }
            if (temp.length > 0) {
                images.push(temp);
            }
            // setCompaniesdata(images);
            setGallaryData(images);
            // setGallaryData(res.data.list);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getGallery();
    }, []);

    console.log(gallerydata, "gsjdshbdsfbdsds");
    return (
        <>
            <section className='gallaryswiper_section'>
                <Container>
                    <div className='gallary_swiper_main desktopvieww'>
                        <Swiper
                            className="mySwiper"
                            modules={[Pagination]}
                            pagination={{ clickable: true }}
                        //   onSwiper={(swiper) => setSwiperInstance(swiper)}
                        >
                            {gallerydata?.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <div>
                                        <Row>
                                            {item[0] && (
                                                <Col xxl={6} xl={6} lg={6} md={6}>
                                                    <div className='imagebig_div'>
                                                        <img className="imgbig" src={imageUrl + item[0].image} alt="Logo" />
                                                    </div>
                                                </Col>
                                            )}
                                            <Col xxl={6} xl={6} lg={6} md={6}>
                                                <div className='imagebi_div'>
                                                    <Row>
                                                        {item[1] && (
                                                            <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
                                                                <div className='imagesmall_div'>
                                                                    <img className="imgsmall" src={imageUrl + item[1].image} alt="Logo" />
                                                                </div>
                                                            </Col>
                                                        )}
                                                        {item[2] && (
                                                            <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
                                                                <div className='imagesmall_div'>
                                                                    <img className="imgsmall" src={imageUrl + item[2].image} alt="Logo" />
                                                                </div>
                                                            </Col>
                                                        )}
                                                        {item[3] && (
                                                            <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
                                                                <div className='imagesmall_div'>
                                                                    <img className="imgsmall" src={imageUrl + item[3].image} alt="Logo" />
                                                                </div>
                                                            </Col>
                                                        )}
                                                        {item[4] && (
                                                            <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
                                                                <div className='imagesmall_div'>
                                                                    <img className="imgsmall" src={imageUrl + item[4].image} alt="Logo" />
                                                                </div>
                                                            </Col>
                                                        )}
                                                    </Row>

                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>

                    </div>

                    <div className='gallary_swiper_main mobileviewss'>
                        <div className='mt-5'>
                            <img className="imgbig" src={process.env.PUBLIC_URL + "/assets/images/Gallary/hospital _room.png"} alt="Logo" />
                        </div>
                        <Swiper
                            className="mySwiper"
                            modules={[Pagination]}
                            pagination={{ clickable: true }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                420: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },

                                486: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                                },
                                992: {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                                },
                                1200: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },

                            }}
                        //   onSwiper={(swiper) => setSwiperInstance(swiper)}
                        >
                            {data2.map((item, index) => (
                                <SwiperSlide key={index}>

                                    <div className=''>
                                        <img className="imgsmall" src={item.images} alt="Logo" />
                                    </div>

                                </SwiperSlide>
                            ))}
                        </Swiper>

                    </div>



                </Container>
            </section>

        </>
    )
}

export default GallarySwiper